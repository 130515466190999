/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
    query NotifyReadQuery($customerId: ID) {
        NotifyRead(customerId: $customerId) {
            message,
            status
        }
    }

`


/*
 * EXPORTS
 */
export default Index
