/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiNewspaper } from 'react-icons/hi2' // Npm: React icons.
import { Flex, Spinner, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * COMPONENTS
 */
import DateRangePicker from 'components/DateRangePicker'


/*
 * GRAPHS
 */
import SmsCountDlrQuery from './__query__/index.sms.countDlr.query'


/*
 * OBJECTS
 */
const Index = ({ customer, vendor, account }) => {
  // Hook assignment.
  const [countDlr, setCountDlr] = React.useState('')
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const _QuerySmsCountDlr = useQuery(SmsCountDlrQuery, {
    'variables': {
      'customerId': account?.accountType === 'TERMINATION' ? void 0 : customer?.id,
      'vendorId': account?.accountType === 'TERMINATION' ? vendor?.id : void 0,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })

  // Event handler.
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // Refetch data on date range change.
      const { data, error } = await _QuerySmsCountDlr.refetch({
        'customerId': account?.accountType === 'TERMINATION' ? void 0 : customer?.id,
        'vendorId': account?.accountType === 'TERMINATION' ? vendor?.id : void 0,
        'startDate': dateRange?.selection.endDate ? dateRange.selection.startDate : Object.DateRangeProvider().startDate,
        'endDate': dateRange?.selection.endDate ? dateRange.selection.endDate : Object.DateRangeProvider().endDate
      })

      // If getting data caught exception then report failure.
      if (error instanceof Error) throw error

      // Update data on successful read.
      if ('COUNT_SUCCESSFUL' === data?.SmsCountDlr?.status) {
        // Update state.
        setCountDlr(data?.SmsCountDlr?.count)
      }
    }; _Async().catch(i => { throw i })
  }, [dateRange])

  // Return component.
  return (
    <Flex
      my='auto'
      w='100%'
      borderRadius='20px'
      bg='white'
      h='140px'
      p='15px'
      gap='12px'
      flexDir='column'
      color='#1B2559'
      fontSize={['clamp(13px, 1.5vw, 16px)']}
      justify='space-between'
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <DateRangePicker startDate={dateRange?.selection?.startDate} endDate={dateRange?.selection?.endDate} setSelectDate={setDateRange} isLoading={_QuerySmsCountDlr?.loading} />
      {_QuerySmsCountDlr?.loading ? (
        <Spinner mx='auto' my='auto' color='#c1a9f4' />
      ) : (
        <>
          <Flex w='100%' justify='space-between'>
            <Flex
              my='auto'
              display='flex'
              flexDir='column'>
              <Text
                lineHeight='100%'
                color='gray.500'
                fontWeight='600'
                fontSize='13px'
                mb='8px'>
                Dlr Percentage
              </Text>
              <Text
                fontSize={['clamp(15px, 2vw, 25px)']}
                fontWeight='900'
                color='#1B2559'>
                {`${(countDlr)}%`}
              </Text>
            </Flex>
            <Flex
              w='60px'
              h='60px'
              bg='#F1F5FF'
              borderRadius='12px'
              align='center'
              justify='center'>
              <HiNewspaper color='#4308FF' size={25} />
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'customer': PropTypes.object.isRequired,
  'vendor': PropTypes.object.isRequired,
  'account': PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer, 'vendor': __state.Vendor, 'account': __state.Account })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
