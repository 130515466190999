/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: Utility module.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react' // Npm: Chakra UI components.
import { SiCodepen, SiPostman } from 'react-icons/si' // Npm: React icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiXCircle } from 'react-icons/hi2'


/*
 * SIBLINGS
 */
import Modal from '../Modal'
import AccessTokenDelete from '../AccessTokenDelete'


/*
 * GRAPHS
 */
import AccessTokenReadQuery from './__query__/index.accessToken.read.query'


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = ({ account, onUpdate }) => {
  // Const assignment.
  const _bindableTable = 'account'

  // Hook assignment.
  const [accessTokenId, setAccessTokenId] = React.useState('')
  const { 'onOpen': onDeleteOpen, 'isOpen': isDeleteOpen, 'onClose': onDeleteClose } = useDisclosure()
  const _AccessTokenReadQuery = useQuery(AccessTokenReadQuery, { 'variables': { 'bindToTable': _bindableTable, 'bindableId': account?.id }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Event handler.
  React.useEffect(() => {
    // Update token if it is available.
    _AccessTokenReadQuery.refetch(); onUpdate?.()
  }, [isDeleteOpen])

  // Return component.
  return (
    <>
      <Flex
        my='0'
        w='100%'
        borderRadius='20px'
        bg='white'
        p='22px'
        gap='12px'
        flexDir='column'
        className='apiCollectionAndTokens'
        color='#1B2559'
        fontSize={['clamp(13px, 1.5vw, 16px)']}
        justify='space-between'
        boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
        <Text as='h2' fontSize='xl' color='#393A41'>API Collections</Text>
        <Text w='100%' as='p' fontSize='md' mt='0' my='12px' mb='24px' color='gray.500' gap='6px' className='heading'>Click <Text as='span' cursor='pointer' fontWeight={700} color='#FE6C37' onClick={() => window.open('https://api.postman.com/collections/35122728-95305f27-3a0b-414b-aeb6-f9822d70ce8c?access_key=PMAT-01HYGXFHWXJD0NXD9ZBRPVEAB5')}><SiPostman />Postman Collection</Text>&nbsp;&nbsp;to checkout API and its usage in API Testing tool or you can try out <Text cursor='pointer' as='span' fontWeight={700} color='black' onClick={() => window.open('https://codepen.io/Nitesh-Yadav-the-sans/pen/XWwKNwL?editors=0010')}><SiCodepen size={19} />Codepen.io</Text>&nbsp;&nbsp;</Text>
        <Text as='h2' fontSize='xl' color='#393A41'>Previous Tokens</Text>
        <Text w='100%' as='p' fontSize='md' mt='0' my='12px' mb='24px' color='gray.500' gap='6px' className='heading'>These are the active tokens which can make smpp resources available to api channels. Destroying one will make api in accessible to respective Smpp resources.</Text>
        <Flex as='div' flexDir='column' h='100%' gap='12px'>
          {
            _.any(_AccessTokenReadQuery?.data?.AccessTokenRead, j => 'NO_TOKEN_FOUND' === j.status) ? (<Flex h='100%' w='100%' flexDir='column' justifyContent='center' alignItems='center' color='gray.500'><HiXCircle size={21} /><Text mt='12px' fontWeight={600} letterSpacing='-0.26px'>No Token Found</Text></Flex>) : _AccessTokenReadQuery?.data?.AccessTokenRead?.map(j => (<Button key={String.random(8)} py='12px' color='brand.500' bg='gray.50' _hover={{ 'color': 'white', 'bg': 'brand.500' }} _focus={{ 'color': 'white', 'bg': 'brand.500' }} display='flex' borderRadius={12} justifyContent='space-between' onClick={() => { setAccessTokenId(j.id); onDeleteOpen?.() }}>{j.token}<HiXCircle size={21} /></Button>))
          }
        </Flex>
      </Flex>
      <Modal
        size='md'
        title='Access Token Delete'
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}>
        <AccessTokenDelete bindToTable={_bindableTable} bindableId={account?.id} accessTokenId={accessTokenId} />
      </Modal>
    </>
  )
}



/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object.isRequired,
  'onUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account })
const _MapDispatchToProps = __dispatch => ({ 'AccountUpdate': __account => __dispatch({ 'type': 'ACCOUNT_UPDATE', 'Account': __account }) })


/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)
