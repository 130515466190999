/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import AutoSizer from 'react-virtualized-auto-sizer' // Npm: React virtualized auto sizer.
import InfiniteLoader from 'react-window-infinite-loader' // Npm: React window infinite loader.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useQuery, useLazyQuery } from '@apollo/client' // Npm: Apollo client.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { FixedSizeList as List } from 'react-window'; // Npm: React Virtualized for virtualized list.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  Spinner,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import TableSpinner from 'components/TableSpinner'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import ContactBookDirectoryReadQuery from './__query__/index.contactBookDirectory.read.query'
import ContactBookReadQuery from './__query__/index.contactBook.read.query'


/*
 * OBJECTS
 */
const Index = ({ customer, bg, color, contactBookDirectoryValue, contactBookValue, inValidContactBook, disabled, inValidContactBookDirectory, isRequired, onChange }) => {
  // Hook assignment.
  const [, setForceReRender] = React.useState(void 0)
  const [contactBookDirectory, setContactBookDirectory] = React.useState(contactBookDirectoryValue)
  const [contactBook, setContactBook] = React.useState([])
  const [QueryContactBookRead, QueryContactBookReadResponse] = useLazyQuery(ContactBookReadQuery)
  const _QueryContactBookDirectoryRead = useQuery(ContactBookDirectoryReadQuery, { 'variables': { 'customerId': customer?.id, 'take': 5000, 'skip': 0 } })
  const _contactBooksRef = React.useRef([])

  // Object assignment.
  const _LoadContactBook = async (__take, __skip, __doForceReRender) => {
    // If contactBookDirectory has value then fetch contactBook
    // For the selected contactBookDirectory.
    if (contactBookDirectory) {
      // Const assignment.
      const _contactBookDirectoryId = contactBookDirectory?.split?.('(')?.[1]?.split?.(')')?.[0]
      const _QueryContactBookRead = await QueryContactBookRead({ 'variables': { 'contactBookDirectoryId': _contactBookDirectoryId, 'skip': __skip, 'take': __take } })

      // If contact book fetch caught an error then report it.
      if (_QueryContactBookRead?.error) return console.error(_QueryContactBookRead?.error)

      // Update contactBook.
      _contactBooksRef.current = _.uniq(__doForceReRender ? (_QueryContactBookRead?.data?.ContactBookRead) : [..._contactBooksRef.current, ...(_QueryContactBookRead?.data?.ContactBookRead ?? [])])
    }

    // If force re-render is needed then do it.
    __doForceReRender && setForceReRender(Math.random())
  }

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if contactBookDirectory value
     * Is passed in params.
     */
    contactBookDirectoryValue && _.isEmpty(contactBookDirectory) && setContactBookDirectory(contactBookDirectoryValue ?? '')
    contactBookValue && _.isEmpty(contactBook) && setContactBook(contactBookValue ?? [])
  }, [contactBookDirectoryValue, contactBookValue])
  React.useEffect(() => {
    // Load contactBook if directory is known.
    contactBookDirectory && _LoadContactBook(10, 0, true)
  }, [contactBookDirectory])

  // Const assignment.
  const _selectedTarget = (contactBookDirectory)?.split?.('(')?.[1]?.split?.(')')?.[0]
  const _selectedContactBookDirectory = _QueryContactBookDirectoryRead?.data?.ContactBookDirectoryRead?.filter?.(j => 0 < j.id?.includes(_selectedTarget))
  const _firstSelectedContactBookDirectory = _selectedContactBookDirectory?.[0]

  // Component assignment.
  const _List = ({ index, style }) => _.isEmpty(_contactBooksRef.current[index]?.contactBook) ? void 0 : (
    <MenuItem closeOnSelect={false} style={style}>
      <Checkbox
        disabled={0 === _selectedContactBookDirectory?.length}
        name='contactBook'
        onChange={() => {
          // Update state of contactBooks.
          setContactBook(j => {
            // const assignment.
            const _bookId = `${_contactBooksRef.current[index]?.contactBook} (${_contactBooksRef.current[index]?.id})`

            /*
             * If only one selection is allowed
             * Then clear the array.
             */
            if (j.includes(_bookId)) {
              // Const assignment.
              const _data = _.without(j, _bookId, 'ALL')

              // Return updated mcc and mnc.
              onChange({ 'contactBook': _data, 'contactBookDirectory': contactBookDirectory, 'raw': _data, 'cleared': _bookId })

              // Update state of contactBooks.
              return _data
            }

            // Return updated contactBook.
            onChange({ 'contactBook': [...j, _bookId], 'contactBookDirectory': contactBookDirectory, 'raw': [...(j.raw ?? []), _bookId] })

            // Update state of contactBooks.
            return [...j, _bookId]
          })
        }}
        isChecked={contactBook.includes('ALL') || contactBook.includes('ALL (ALL)') || contactBook.includes(`${_contactBooksRef.current[index].contactBook} (${_contactBooksRef.current[index]?.id})`)}>
        {_contactBooksRef.current[index].contactBook}&nbsp;({_contactBooksRef.current[index]?.id})
      </Checkbox>
    </MenuItem>
  )

  // Data assignment.
  const _isLoading = QueryContactBookReadResponse.loading || _QueryContactBookDirectoryRead?.loading || disabled
  const _isSubChildLoading = _.isEmpty(_firstSelectedContactBookDirectory) || disabled || 0 === _firstSelectedContactBookDirectory?.ContactBook?.length

  // Return the JSX.
  return (
    <Flex w='100%' flexDir='column' gap='22px'>
      <MemoizedSelect
        color={color}
        bg={bg}
        label='Contact Book Folder'
        disabled={_isLoading || disabled}
        name='contactBookDirectory'
        value={contactBookDirectory}
        placeholder='Select Folder'
        options={_.compact(_QueryContactBookDirectoryRead?.data?.ContactBookDirectoryRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidContactBookDirectory}
        onChange={__event => {
          // Update states.
          setContactBookDirectory(__event.target.value)
          setContactBook([])

          // Return updated contactBookDirectory and contactBook.
          onChange({
            'contactBook': [],
            'contactBookDirectory': __event.target.value,
            'raw': []
          })
        }}
      />
      <FormControl flexDir='column'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          Contact{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={_.isEmpty(_firstSelectedContactBookDirectory) || _QueryContactBookDirectoryRead?.loading || 0 === _firstSelectedContactBookDirectory?.ContactBook?.length || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={_isLoading || _isSubChildLoading}
            h='40px'
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            color={color}
            opacity={(_.isEmpty(_firstSelectedContactBookDirectory) || _QueryContactBookDirectoryRead?.loading || 0 === _firstSelectedContactBookDirectory?.ContactBook?.length || disabled) ? 0.5 : 1}
            transition='all 0.2s'
            textAlign='left'
            borderRadius='12px'
            rightIcon={<BsCheckAll />}
            bg={inValidContactBook ? 'rgb(255,255,255,0.8)' : bg ?? 'gray.100'}
            _hover={{ 'bg': bg ?? 'gray.100' }}
            _expanded={{ 'bg': bg ?? 'gray.100' }}
            _focus={{ 'bg': bg ?? 'gray.100' }}
            boxShadow={inValidContactBook ? '0 0 0 1.5px #EE5D50' : void 0}>
            {contactBook.includes('ALL') ? _.first(_contactBooksRef.current)?._totalCount : ((_.isString(contactBook) && !_.isEmpty(contactBook) ? [contactBook] : contactBook)?.length ?? 0)} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' overflow='hidden' w='420px'>
            {
              _contactBooksRef.current.length === 0 ? <TableSpinner isLoading /> : (
                <AutoSizer>
                  {({ height, width }) => (
                    <InfiniteLoader
                      width={width}
                      height={height}
                      isItemLoaded={__index => !!_contactBooksRef.current[__index]}
                      itemCount={_contactBooksRef.current.length}
                      threshold={0.5}
                      loadMoreItems={(__startIndex, __endIndex) => _LoadContactBook(10, parseInt(__endIndex) + 1)}>
                      {({ onItemsRendered, ref }) => (
                        <List
                          ref={ref}
                          height={height}
                          itemCount={_contactBooksRef.current.length}
                          itemSize={32}
                          onItemsRendered={onItemsRendered}
                          overflow='hidden'
                          width={width}>
                          {_List}
                        </List>
                      )}
                    </InfiniteLoader>
                  )}
                </AutoSizer>
              )
            }
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={10}>
            <IconButton
              aria-label='Select All'
              disabled={_isLoading || _isSubChildLoading}
              _disabled={{ 'cursor': 'not-allowed' }}
              isDisabled={_isLoading || _isSubChildLoading}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={async () => {
                // Update contactBook.
                setContactBook(['ALL'])

                // Return updated contactBookIds.
                onChange({ 'contactBook': ['ALL'], contactBookDirectory, 'raw': [] })
              }}
              bg='none'
              p='0'>
              {(contactBook?.length === _firstSelectedContactBookDirectory?.contactBook?.length || contactBook.includes('ALL') ? (<Text color='gray' fontSize='sm'>ALL</Text>) : (<Text color='white' fontSize='sm'>ALL</Text>))}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              disabled={_isLoading || _isSubChildLoading}
              isDisabled={_isLoading || _isSubChildLoading}
              _disabled={{ 'cursor': 'not-allowed' }}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update contactBook.
                setContactBook([])

                // Return updated contactBookIds.
                onChange({ 'contactBook': [], 'contactBookDirectory': '', 'raw': [] })
              }}
              bg='none'
              p='0'>
              {0 === contactBook?.length && !contactBook.includes('ALL') ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu >
      </FormControl >
    </Flex >
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'contactBookDirectoryValue': PropTypes.string,
  'contactBookValue': PropTypes.array,
  'inValidContactBook': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidContactBookDirectory': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool,
  'bg': PropTypes.string,
  'color': PropTypes.string
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
