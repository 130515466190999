/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: apollo client.


/*
 * EXPORTS
 */
export default gql`
    query CreditHistoryReadQuery($customerId: ID!, $startDate: DateTime, $endDate: DateTime) {
        CreditHistoryRead(customerId: $customerId, startDate: $startDate, endDate: $endDate) {
            message,
            status,
            limit,
            balance,
            createdAt,
            History {
              createdAt,
              creditedBy,
              limit,
              balance
            },
            _objectOnRuntimeMemory
        }
    }
`


