/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  useBreakpointValue,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import TableSpinner from 'components/TableSpinner'
import FileUpload from 'components/FileUpload'
import ContactBookDropDownOptions from 'components/ContactBookDropDownOptions'
import ContactBookUpsert from 'components/ContactBookUpsert'
import ContactBookDelete from 'components/ContactBookDelete'


/*
 * GRAPHS
 */
import ContactBookReadQuery from './__query__/index.contactBook.read.query'
import ContactBookBulkCreateMutation from './__mutation__/index.contactBook.bulkCreate.mutation'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [contactBookToSearch, setContactBookToSearch] = React.useState('')
  const [doFileManagerCleanup, setDoFileManagerCleanup] = React.useState(false)
  const [MutationContactBookBulkCreate, MutationContactBookBulkCreateResponse] = useMutation(ContactBookBulkCreateMutation)
  const { 'isOpen': isContactBookCreateOpen, 'onOpen': onContactBookCreateOpen, 'onClose': onContactBookCreateClose } = useDisclosure()
  const { 'isOpen': isContactBookUpdateOpen, 'onOpen': onContactBookUpdateOpen, 'onClose': onContactBookUpdateClose } = useDisclosure()
  const { 'isOpen': isContactBookDeleteOpen, 'onOpen': onContactBookDeleteOpen, 'onClose': onContactBookDeleteClose } = useDisclosure()
  const _QueryContactBookRead = useQuery(ContactBookReadQuery, { 'variables': { 'contactBookDirectoryId': passOn?.contactBookDirectoryId, contactBookToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onContactBookToSearchInputChange = React.useCallback(Debounce(e => setContactBookToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data Assignment.
  if (!_QueryContactBookRead.loading && 0 < _QueryContactBookRead.data?.ContactBookRead?.length && !_isFirstLoadCompleted.current) _isFirstLoadCompleted.current = true
  if (!_.every(_.pluck(_QueryContactBookRead.data?.ContactBookRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='contactBook base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}
          overflowX='hidden'>
          <Flex pb='0px' justify='space-between' gap='12px' flexWrap={{ 'base': 'wrap', 'md': 'nowrap' }}>
            <Flex flexDir='column' gap='12px'>
              <Tooltip label='Create New ContactBook' fontSize='sm'>
                <Button
                  w='max-content'
                  leftIcon={<IoMdAdd />}
                  onClick={onContactBookCreateOpen}
                  style={buttonStyle}>
                  Add Number
                </Button>
              </Tooltip>
            </Flex>
            <Flex gap='12px'>
              <FileUpload
                onChange={j => {
                  // Const assignment.
                  const _numbers = _.uniq(Array.from({ 'length': j?.rows?.length }, (__, i) => _.first(j?.rows[i])));

                  // Execute mutation.
                  return MutationContactBookBulkCreate({ 'variables': { 'contactBookDirectoryId': passOn?.contactBookDirectoryId, 'contactBook': _numbers } }).then(jp => {
                    // On Successful response from the mutation.
                    if (_.every(_.compact(_.pluck(jp?.data?.ContactBookBulkCreate, 'status')), j => j === 'CREATE_SUCCESSFUL')) {
                      // toast success.
                      toast('Successfully uploaded the contacts in your contact book.')

                      // Set cleanup to false.
                      setDoFileManagerCleanup(true)

                      // Refetch data.
                      _QueryContactBookRead.refetch({ 'skip': skipPage * skipDifference, 'take': skipDifference })

                      // setTimeout for file manager cleanup.
                      const j = setTimeout(() => {
                        // Set cleanup to false.
                        setDoFileManagerCleanup(false)

                        // Clear the timeout.
                        clearTimeout(j)
                      }, [1000])
                    } else {
                      // toast failure.
                      toast('Failed to upload the contacts in your contact book.')
                    }
                  })
                }}
                doCleanup={doFileManagerCleanup}
                isLoading={MutationContactBookBulkCreateResponse.loading}
              />
              <DownloadToExcel
                cellsData={0 === _QueryContactBookRead?.data?.ContactBookRead?.length || !_.every(_.pluck(_QueryContactBookRead?.data?.ContactBookRead, 'status'), i => _successFlags.includes(i)) ? [] : _QueryContactBookRead?.data?.ContactBookRead.map((item, __index) => ({
                  'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                  'ContactBook': item.contactBook ?? '-',
                  'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                  'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD')
                }))}
                headersData={['S.No.', 'ContactBook', 'CreatedAt', 'UpdatedAt'].map(i => ({ 'key': i, 'label': i }))} />
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>S.No</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Number</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Created At</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Updated At</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Actions</Td>
                </Tr>
                <Tr key={String.random(5)} >
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Input
                      placeholder='Search'
                      className='filter searchInput'
                      bg='gray.100'
                      defaultValue={contactBookToSearch}
                      onChange={_onContactBookToSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                </Tr>
              </Thead>
              {_QueryContactBookRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryContactBookRead.data?.ContactBookRead?.length || !_.every(_.pluck(_QueryContactBookRead.data?.ContactBookRead, 'status'), i => _successFlags.includes(i))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryContactBookRead.data?.ContactBookRead?.map(({ id, createdAt, updatedAt, contactBook }, __index) => (
                    <Tr key={String.random(5)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {contactBook ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <ContactBookDropDownOptions
                            contactBookId={id}
                            contactBookDirectoryId={passOn?.contactBookDirectoryId}
                            onContactBookUpdate={onContactBookUpdateOpen}
                            onContactBookDelete={onContactBookDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )
              }
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='md'
          title='Contact Book Create'
          isOpen={isContactBookCreateOpen}
          onClose={onContactBookCreateClose}>
          <ContactBookUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='Contact Book Update'
          isOpen={isContactBookUpdateOpen}
          onClose={onContactBookUpdateClose}>
          <ContactBookUpsert />
        </Modal>
        <Modal
          size='md'
          title='Contact Book Delete'
          isOpen={isContactBookDeleteOpen}
          onClose={onContactBookDeleteClose}>
          <ContactBookDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryContactBookRead.data?.ContactBookRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })
const _MapDispatchToProps = __dispatch => ({ 'PassOnUpdate': __payload => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': __payload }) })


/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)
