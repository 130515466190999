/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiCurrencyEuro } from 'react-icons/hi2' // Npm: React icons.
import { Flex, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * GRAPHS
 */
import CreditReadQuery from './__query__/index.credit.read.query'


/*
 * OBJECTS
 */
const Index = ({ customer }) => {
  // Hook assignment.
  const _CreditReadQuery = useQuery(CreditReadQuery, { 'variables': { 'customerId': customer?.id }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Return component.
  return (
    <Flex
      w='100%'
      borderRadius='20px'
      p='15px'
      gap='12px'
      background='linear-gradient(90deg, #FCDAE7 0%, #FFEDD4 100%)'
      WebkitBackgroundClip='text'
      WebkitTextFillColor='transparent'
      flexDir='row'
      alignItems='center'
      fontSize={['clamp(13px, 1.5vw, 16px)']}
      justify='space-between'
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex flexDir='row' gap='6px' alignItems='center' px='22px'>
        <HiCurrencyEuro fontSize='21px' />
        <Text as='h2' fontSize='xl' color='black'>Credit Status</Text>
      </Flex>
      <Flex flexDir='column' alignItems='center' gap='6px' px='22px'>
        <Text position='relative' top='2.5px' w='max-content' fontSize='lg'>Active Balance <Text as='span' color='gray.600'>{((_CreditReadQuery?.data?.CreditRead?._objectOnRuntimeMemory ?? _CreditReadQuery?.data?.CreditRead)?.balance ?? 0)?.toFixed(2)}</Text>&nbsp;{customer?.Billing?.currency}</Text>
        <Text position='relative' top='-2.5px' w='max-content' fontSize='lg' fontWeight={600}>Over draft limit is <Text as='span' color='gray.600'>{(customer?.overDraftLimit ?? 0)?.toFixed?.(2)}</Text>&nbsp;{customer?.Billing?.currency}</Text>
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'customer': PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
