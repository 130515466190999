/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import * as XLSX from 'xlsx' // Npm: XLSX library.
import _ from 'underscore' // Npm: Underscore.js library.
import { PiFileCsvFill } from "react-icons/pi"; // Npm: React icons library.
import { HiFolderPlus, HiFolderMinus, HiFolder } from 'react-icons/hi2' // Npm: react icons library.
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  Flex,
  Spinner,
  useDisclosure,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * SIBLINGS
 */
import Modal from 'components/Modal'


/*
 * OBJECTS
 */
const Index = ({ bg, color, doCleanup, columnEndPosition = 1, onChangeWithFileReader, clearIconColor, label, uploadingFile, onChange, onFileUpload, onClear, disabled, isLoading, ...props }) => {
  // Hook assignment.
  const [forceReRender, setForceReRender] = React.useState(void 0)
  const [filename, setFilename] = React.useState(void 0)
  const _fileRef = React.useRef({})
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })
  const { 'isOpen': isContactBookCreateOpen, 'onOpen': onContactBookCreateOpen, 'onClose': onContactBookCreateClose } = useDisclosure()

  // Object assignment.
  const _onClear = () => { _fileRef.current.target && (_fileRef.current.value = void 0); onChange?.([]); setFilename(void 0); setForceReRender(String.random(9)); onClear?.({}) }
  const _FileUpload = e => Promise.all(Array.from(e.target.files).map(file => {
    // Const assignment.
    const _FileReader = new FileReader()

    // Read file as binary string.
    _FileReader.readAsBinaryString(file)

    // Event handler.
    _FileReader.onload = () => {
      // Const assignment.
      const _data = _FileReader.result

      // Const assignment.
      const _raw = _data.split('\r\n')

      // Set filename.
      setFilename(file.name)

      // Create row by mapping through _raw data.
      const _rows = Array.from({ length: _raw.length }, (v, i) => _raw[i].split(','));

      // Execute mutation.
      return onChange?.({ 'columns': _rows[0], 'rows': _rows.slice(1, _raw.length) })
    }
  }))
  const _onChange = (e) => {
    // If file reader is provided.
    if (onChangeWithFileReader) {
      // Update file name.
      setFilename(e.target.files[0].name)

      // Execute mutation.
      return onChangeWithFileReader?.(e)
    }

    // Return file upload.
    return _FileUpload(e)
  }

  // Component assignment.
  const FileUploadComponent = () => (
    <Flex flexDir='column' gap='22px'>
      <Text color='gray.500' fontSize='lg'>Currently platform supports CSV only and please make sure to format your csv correctly.</Text>
      <Text as='span' color='tomato' fontSize='sm' fontWeight={700}>NOTE : Platform doesn't do lookup in csv's so make sure your entries are unique when needed.</Text>
      <Flex alignItems='center' gap='12px' justifyContent='space-between'>
        <Flex width='calc(100% - 55px)'  flexDir='column' position='relative' gap='12px'>
        <Input
          ref={_fileRef}
          position='relative'
          left='-12px'
          type='file'
          cursor='pointer'
          border='none'
          opacity='0'
          zIndex={100}
          disabled={disabled}
          bg='transparent'
          w={{ 'base': '100%', 'md': 'auto' }}
          sx={{
            '::file-selector-button': {
              'height': 10,
              'padding': 0,
              'mr': 4,
              'fontWeight': '400',
              'background': 'none',
              'border': 'none',
              'color': 'brand.500'
            }
          }}
          _focus={{
            'border': 'none'
          }}
          _hover={{
            'border': 'none'
          }}
          {...props}
          key={forceReRender}
          onChange={_onChange}
        />
        <Flex pt='8px' pb='8px' pl='22px' pr='22px' w='100%'  borderRadius='12px' borderWidth={0} overflow='hidden' bg={_.isEmpty(filename) ? (bg ?? 'gray.100') : 'red.100'} color={_.isEmpty(filename) ? (color ?? 'gray.600') : 'tomato'} position='absolute' zIndex='-100' cursor='pointer' disabled={disabled} opacity={disabled ? 0.5 : 1} gap='12px' alignItems='center' justifyContent='space-between'>
          <Flex alignItems='center' htmlFor='file' _hover={{ 'bg': 'transparent' }} flexDir='row' gap='6px'>{_isCurrentViewMobile ? void 0 : (<Text as='h4' cursor='pointer'>{filename ?? label ?? 'e.g. "Awesome.csv"'}</Text>)}</Flex>
        </Flex>
      </Flex>
        <Flex w='55px' color={_.isEmpty(filename) ? (color ?? 'gray.600') : 'tomato'} cursor='pointer' disabled={disabled} opacity={disabled ? 0.5 : 1} gap='12px' alignItems='center' justifyContent='end'>
        {uploadingFile || isLoading ? (<Spinner color='var(--chakra-colors-brand-500)' size='md' />) : _.isEmpty(filename) ? (<HiFolderPlus color='var(--chakra-colors-brand-500)' size={41} />) : (<Box color={clearIconColor ?? 'tomato'} position='relative' zIndex={10} onClick={_onClear}><HiFolderMinus size={41} /></Box>)}
      </Flex>
      </Flex>
    </Flex>
  )

  // Event handler.
  React.useEffect(() => {
    // if cleanup is needed.
    if (doCleanup) {
      // Clear file.
      _onClear()

      // Close modal.
      onContactBookCreateClose()
    }
  }, [doCleanup])

  // Return component.
  return (
    <>
    <FormControl cursor='pointer' w='inherit' disabled={disabled} opacity={disabled ? 0.5 : 1} h='40px' bg={_.isEmpty(filename) ? (bg ?? 'brand.100') : 'red.100'} color={_.isEmpty(filename) ? (color ?? 'brand.600') : 'tomato'} borderRadius='12px' borderWidth={0} overflow='hidden'>
      <FormLabel onClick={() => isContactBookCreateOpen ? onContactBookCreateClose() : onContactBookCreateOpen()} h='100%' display='flex' alignItems='center' ml='18px' mr='16px' htmlFor='file' _hover={{ 'bg': 'transparent' }} flexDir='row' gap='6px'><PiFileCsvFill size={21} /> {filename ?? label ?? 'File Uploader'}</FormLabel>
    </FormControl>
    <Modal
      size='md'
      title='File Uploader'
      isOpen={isContactBookCreateOpen}
      onClose={onContactBookCreateClose}>
      <FileUploadComponent />
    </Modal>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'bg': PropTypes.string,
  'color': PropTypes.string,
  'label': PropTypes.string,
  'uploadingFile': PropTypes.bool,
  'onChange': PropTypes.func,
  'onClear': PropTypes.func,
  'disabled': PropTypes.bool,
  'isLoading': PropTypes.bool
}


/*
 * EXPORTS
 */
export default Index
